<template>
  <div class="hh_exam_wrap">
    <div class="wxq_exam" v-for="(item, index) in examList" :key="index">
      <div class="wxq_exam_time">
        <p>
          考试时间：
          <span>{{ item.timeLimit ? `${item.startTime} ~ ${item.endTime}` : '不限时' }}</span>
        </p>
        <div :class="['state', stateClass.get(item.state)]">{{ examMap.get(item.state) }}</div>
      </div>
      <div class="wxq_exam_detail">
        <p class="wxq_exam_detail_title">{{ item.title }}</p>
        <p class="wxq_exam_detail_author">
          <span>{{ item.userName }}&emsp;创建于&nbsp;{{ goTime(item.createTime) }}</span>
          <span>{{ item.peopleCount }}人已参与</span>
        </p>
        <div class="wxq_sum_limit">
          <p>
            <span class="wxq_exam_sum color_blue">共{{ item.quCount }}题</span>
          </p>
          <el-button class="btn btn_blue" :disabled="item.state !== 2" @click="toExam(item)">开始考试</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { createExam } from '@/apis/studentsEndAPI/exam/index.js';
import { EXAM_STATUS, LockMap } from '@/utils/enum.js'
export default {
  name: 'examCom',
  props: ['examList'],
  data() {
    return {
      examMap: EXAM_STATUS,
      stateClass: new LockMap([
        [1, 'soon'],
        [2, 'ing'],
        [3, 'ed'],
        [4, 'ed'],
      ]),
    }
  },
  methods: {
    // 开始考试
    async toExam(exam) {
      if (sessionStorage.getItem('educationToken') || localStorage.getItem('educationToken')) {
       const { times, tryCount = 0 } = exam;
      if (times) {
        if (times - tryCount < 0 || !(times - tryCount)) {
          return this.$message.warning('您没有剩余的考试次数了');
        }
      }
      const { data } = await createExam({
        examId: exam.id,
        password: ''
      });
      this.$router.push(`/answer?id=${data.id}`);
      } else {
        this.$message.warning('请先登录！')
        this.$store.state.isLogin = true
      }
    },
    goTime(val) {
      var timestamp4 = new Date(val)
      //利用拼接正则等手段转化为yyyy-MM-dd hh:mm:ss 格式
      return timestamp4.toLocaleDateString().replace(/\//g, '-')
    },
  },
}
</script>
<style lang='scss' scoped>
@import "src/assets/css/studentsEndCSS/home";
.state {
	padding: 0 8px;
	height: 24px;
	border-radius: 100px 0 0 100px;
	color: #fff;
	font-family: PingFangSC-Medium, PingFang SC;
	line-height: 24px;
	&.ing {
		background: var(--lightGreen);
	}
	&.ed {
		background: #ced5e0;
	}
	&.soon {
		background: var(--colorOrange);
	}
}
</style>
