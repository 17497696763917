import http from '@/plugins/http.js'

const { axios, post } = http

// 考试列表
export const fetchExamList = (data) => {
  return post(`/exam/api/exam/exam/online-paging`, data)
}

// 试卷详情
export function paperDetail(data) {
  return post('/exam/api/paper/paper/paper-detail', data)
}

/**
 * 题目详情
 */
export function quDetail(data) {
  return post('/exam/api/paper/paper/qu-detail', data)
}

/**
 * 填充答案
 */
export function fillAnswer(data) {
  return axios.request({
    url: '/exam/api/paper/paper/fill-answer',
    method: 'post',
    data,
    headers: {
      needToast: false
    }
  })
}

/**
 * 交卷
 */
export function handExam(data) {
  return axios.request({
    url: '/exam/api/paper/paper/hand-exam',
    method: 'post',
    data,
    headers: {
      needToast: false
    }
  })
}

// 创建考试
export const createExam = data => {
  return post('/exam/api/paper/paper/create-paper', data)
}

// 考试结果
export function paperResult(data) {
  return post('/exam/api/paper/paper/paper-result', data)
}


// 考试成绩
export function fetchMyExam(data) {
  return post('/exam/api/user/exam/my-paging', data)
}

// 考试详情
export function fetchExamDetail(data) {
  return post('/exam/api/paper/paper/paging', data)
}