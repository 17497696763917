export class LockMap extends Map {
  constructor(args) {
    super()
    const map = new Map(args)
    map.set = () => {
      console.error('禁止修改枚举常量')
    }
    map.delete = () => {
      console.error('禁止修改枚举常量')
    }
    map.clear = () => {
      console.error('禁止修改枚举常量')
    }
    return map
  }
}

// 考试状态
export const EXAM_STATUS = new LockMap([
  [1, '未开始'],
  [2, '进行中'],
  [3, '已结束'],
  [4, '已关闭']
])

// 考试审核状态
export const EXAM_CHECK_STATUS = new LockMap([
  [1, '审核中'],
  [2, '通过'],
  [3, '不通过']
])

// 题目类别
export const QUESTION_TYPE = new LockMap([
  [1, '单选题'],
  [2, '多选题'],
  [3, '判断题'],
  [4, '填空题']
])

// 题目类别
export const QUESTONAIRE_QUESTION_TYPE = new LockMap([
  [1, '单选题'],
  [2, '多选题'],
  [4, '填空题']
])

// 问卷类型
export const QUESTIONAIRE_TYPE = new LockMap([
  ['1', '护理满意度'],
  ['2', '医护评价'],
  ['3', '护工评价'],
  ['4', '环境评价'],
  ['5', '打分评价'],
  ['6', '宣教问卷']
])

// 问卷状态
export const QUESTIONAIRE_STATUS = new LockMap([
  [0, '未发布'],
  [1, '停止收集'],
  [2, '收集中'],
  [3, '已结束']
])

// 问卷绑定状态
export const QUESTIONAIRE_BIND_STATUS = new LockMap([
  [1, '已绑定'],
  [2, '未绑定']
])

//观看限制,点赞倍数
export const  LIKE_MULTIPLE = new LockMap([
  [1,'1'],
  [2,'2'],
  [3,'3'],
  [4,'4'],
  [5,'5'],
  [6,'6'],
  [7,'7'],
  [8,'8'],
  [9,'9'],
  [10,'10'],
])